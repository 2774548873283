<template>
  <div v-html="html"></div>
</template>

<script>
export default {
  name: "payGateWay",
  data() {
    return {
      html: "",
      phone: this.$route.query.phone,
    }
  },
  mounted() {
    this.pay()
  },
  methods: {
    async pay() {
      const res = await this.request({
        method: "post",
        url: "https://api.iptzx.com/crmapi/applyH5Pay",
        data: {
          phone: this.phone,
          type: 2,
        },
      })
      this.html = res.data.data
      this.$nextTick(() => {
        document.forms[0].submit() //渲染支付宝支付页面
      })
    },
  },
}
</script>

<style></style>
